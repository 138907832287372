/** @format */

const crypto = require('crypto');
export default {
  data() {
    return {
      date_start: new Date().firstMonthDay,
      date_end: new Date().lastMonthDay,
      rang: { 1: 'student.png', 2: 'driver.png', 3: 'master.png', 4: 'profi.png', 5: 'legend.png', 0: 'beginner.png' },
      rewards1: [
        { name: 'test-done', title: 'За тест', ignore: true },
        { name: 'super-series', value: 0, error: 0, isSpace: 0, replace: 1, bonus: 0, title: 'Подряд без ошибок', image: 'super-series.png', type: '' },
        { name: 'single-series', value: 0, error: 2, isSpace: 0, replace: 1, bonus: 0, title: 'Подряд пройдено', image: 'single-series.png', type: '' },
        { name: 'super-5', value: 5, error: 0, isSpace: 0, multiple: 1, bonus: 1000, title: 'Подряд 5 без ошибок', image: 'super-5.png', type: 'rewards', rang: 2 },
        { name: 'super-40', value: 40, error: 0, isSpace: 1, multiple: 0, bonus: 2000, title: '40 без ошибок', image: 'super-40.png', type: 'rewards', rang: 3 },
        { name: 'super-100', value: 100, error: 0, isSpace: 1, multiple: 0, bonus: 5000, title: '100 без ошибок', image: 'super-100.png', type: 'rewards', rang: 4 },
        { name: 'super-300', value: 300, error: 0, isSpace: 1, multiple: 0, bonus: 10000, title: '300 без ошибок', image: 'super-300.png', type: 'rewards', rang: 5 },
        { name: 'single-5', value: 5, error: 2, isSpace: 1, multiple: 0, bonus: 100, title: '5 пройдено', image: 'single-5.png', type: 'rewards', rang: 1 },
        { name: 'single-50', value: 50, error: 2, isSpace: 1, multiple: 0, bonus: 1000, title: '50 пройдено', image: 'single-50.png', type: 'rewards', rang: 2 },
        { name: 'single-80', value: 80, error: 2, isSpace: 1, multiple: 0, bonus: 2000, title: '80 пройдено', image: 'single-80.png', type: 'rewards', rang: 3 },
        { name: 'single-200', value: 200, error: 2, isSpace: 1, multiple: 0, bonus: 5000, title: '200 пройдено', image: 'single-200.png', type: 'rewards', rang: 4 },
        { name: 'single-500', value: 500, error: 2, isSpace: 1, multiple: 0, bonus: 10000, title: '500 пройдено', image: 'single-500.png', type: 'rewards', rang: 5 },
      ],
      rewards: [
        { name: 'test-done', title: 'За тест', ignore: true },
        { name: 'super-series', value: 0, error: 0, isSpace: 0, replace: 1, bonus: 0, title: 'Подряд без ошибок', image: 'super-series.png', type: '' },
        { name: 'single-series', value: 0, error: 2, isSpace: 0, replace: 1, bonus: 0, title: 'Подряд пройдено', image: 'single-series.png', type: '' },
      ],
      rewardsReady: false,
      currentRewards: null,
      currentRating: null,
      stat: null,
    };
  },
  created() {},
  destroyed() {},
  computed: {
    rangImage() {
      if (!this.currentRating) return null;
      const n = this.rang?.[this.currentRating.rang] || 'beginner.png';

      return 'https://joldo.kg/uploads/rewards/' + n;
    },
  },
  methods: {
    getImage(el) {
      let res = 'https://joldo.kg/uploads/rewards/';
      return el.image ? res + el.image : null;
    },
    readReward(n = '') {
      return this.rewards.find((el) => el.name == n);
    },
    assignScore(time, result) {
      let res = 0;
      if (time >= 0 && time <= 10) {
        res = result ? 10 : -1;
      } else if (time >= 11 && time <= 20) {
        res = result ? 9 : -1;
      } else if (time >= 21 && time <= 30) {
        res = result ? 8 : -2;
      } else if (time >= 31 && time <= 40) {
        res = result ? 7 : -3;
      } else if (time >= 41 && time <= 50) {
        res = result ? 6 : -4;
      } else if (time > 51) {
        res = result ? 5 : -5;
      }
      return res;
    },
    async spaceRewards(results) {
      const now = new Date();
      const now_date = now.datetime;
      let current = [];
      let stat = [];
      let error = 0;
      for (let i = 1; i <= this.maxBQ; i++) {
        error += results[i].result == 2 ? 1 : 0;
      }
      if (error == 1) stat.push({ name: 'error-0-row', value: 0 });
      if (error == 3) stat.push({ name: 'error-2-row', value: 0 });
      //console.log('set stat', stat);
      this.setRewardStat(stat);
      return;
      let res = await this.getRewards({ status: 1 });
      this.rewards
        .filter((f) => !f.ignore && f.isSpace == 0)
        .forEach((r) => {
          //console.log(r);
          let curr = res.find((el) => el.name == r.name && el.status == 1);
          let v = 0;
          let id = null;
          let cc = 0;
          let point = 0;
          if (curr) {
            const curr_date = new Date(curr.regdate.replace('Z', ''));
            let end_date = new Date(this.date_end).addDays(1);
            //console.log('dates', { s: this.date_start, c: new Date(curr.regdate.replace('Z', '')), cs: curr.regdate, e: this.date_end.addDays(1) }, curr);
            //проверяю если прогресс актуальный для сезона
            id = curr.id;
            if (curr_date > this.date_start && curr_date < end_date) {
              if (r.replace) point = curr.points || 0; //счетчив всех попаданий в условик
              cc = curr.count;
              v = !r.isSpace && r.error < error ? 0 : curr.value; //если должно быть подряд и не проходит по условию - обнуляю
              if (r.error + 1 == error) {
                current.push({ id: id, name: r.name, value: v, regdate: now_date, count: 0, status: 1, points: point });
              }
            }
          }
        });
      if (current.length) {
        //console.log('set zero spaced rewards', current);
        //await this.setRewards(current);
      }
    },
    async makeMarathon(results, time) {
      let stat = [];
      let res = { time: 0, correct: 0, skip: 0, error: 0 };
      for (let i = 1; i <= this.maxBQ; i++) {
        res.time += results[i].time;
        res.correct += results[i].result === 1 ? 1 : 0;
        res.error += results[i].result === 2 ? 1 : 0;
        res.skip += results[i].result === 0 ? 1 : 0;
      }
      let points = res.skip > 0 ? 0 : res.error > 200 ? 0 : res.error > 80 ? 200 : res.error > 40 ? 1600 : res.error > 1 ? 1800 : res.error === 0 ? 2500 : 0;
      stat.push({ name: 'marathon-done', value: 1, data: `${res.correct}#${res.error}#${res.skip}#${time}#${points}` });
      if (this.auth.id == 36) console.log('marathon', stat);
      this.currentRewards = [{ status: 2, points }];

      await this.setRewardStat(stat);
      await this.getRating();
      this.rewardsReady = true;
    },
    async makeRewards(results, type = '') {
      this.rewardsReady = !true;
      const now = new Date();
      let current = [];
      let time = 0;
      let value = 0;
      let rang = 0;
      let stat = [];
      let correct = 0;
      let error = 0;
      let er = 0;
      let testPoints = 0;
      let point = 0;
      const now_date = now.datetime;
      console.log('results', results);
      for (let i = 1; i <= this.maxBQ; i++) {
        time += results[i].time;
        correct += results[i].result == 1 ? 1 : 0;
        er += results[i].result !== 1 ? 1 : 0;
        point += results[i].point;
        //value += this.assignScore(results[i].time, results[i].result == 1);
      }
      point = point < 0 ? 0 : point;
      error = this.maxBQ - correct;
      if (error == 0) {
        stat.push({ name: 'error-0' + (type == 'rush' ? '-rush' : ''), value: 1 });
        //value = type == 'rush' ? 35 : 25;
        value = point;
      } else if (error <= 2) {
        stat.push({ name: 'error-2' + (type == 'rush' ? '-rush' : ''), value: 1 });
        //value = type == 'rush' ? 30 : 20;
        value = point;
      }
      if (error <= 2) stat.push({ name: 'test-time', value: time });
      stat.push({ name: 'test-done', value: 1 });

      //console.log('value', { value, time, correct, results });
      value = value < 0 || error > 8 ? 0 : value;
      if (value) {
        current.push({ name: 'test-done', points: value, regdate: now_date, status: 2, value: null, count: 1, ignore: true, type: 'test', title: 'За тест' });
        testPoints += value;
      }
      //console.log('rewards value', value);
      let res = await this.getRewards({ status: 1 });
      //console.log('rewards res', res);
      this.rewards
        .filter((f) => !f.ignore)
        .forEach((r) => {
          //console.log(r);
          let curr = res.find((el) => el.name == r.name && el.status == 1);
          let v = 0;
          let id = null;
          let cc = 0;
          let point = 0;
          if (curr) {
            const curr_date = new Date(curr.regdate.replace('Z', ''));
            let end_date = new Date(this.date_end).addDays(1);
            //console.log('dates', { s: this.date_start, c: new Date(curr.regdate.replace('Z', '')), cs: curr.regdate, e: this.date_end.addDays(1) }, curr);
            //if (this.auth.id == 36) console.log('check date', now_date, curr_date, this.date_start, end_date);
            if (curr_date > this.date_start && curr_date < end_date) {
              //проверяю если прогресс актуальный для сезона
              //прогресс текущего сезона, беру значение прогресса
              if (r.replace) point = curr.points || 0; //счетчик всех попаданий в условие
              v = !r.isSpace && r.error < error ? 0 : curr.value; //если должно быть подряд и не проходит по условию - обнуляю
              cc = curr.count; //количество уже полученных наград
            } else {
              //прогресс прошлого сезона, обнуляем счетчики
            }
            id = curr.id;
          } else {
          }

          if (r.error >= error) {
            if (r.replace) point++;
            v++;
          }
          if (r.replace) cc = v > cc ? v : cc; //если перезаписываемый счетчик, то

          if (v && v == r.value) {
            v = 0;
            if (cc == 0 || r.multiple) {
              cc++;
              current.push({ id: null, name: r.name, regdate: now_date, points: r.bonus, status: 2, value: null, count: 1, type: r.type });
              testPoints += r.bonus;
            }
          }
          current.push({ id: id, name: r.name, value: v, regdate: now_date, count: cc, status: 1, points: point });
          //console.log('revards ', r.name, { v, cc, id, r });
        });
      //console.log('rewards total', current);

      if (this.$root.v2) {
        //await this.setRewards(current);
        if (testPoints) stat.push({ name: 'user-rating-test', value: testPoints });
        await this.setRewardStat(stat);

        await this.getRating(testPoints);
      }
      this.currentRewards = current;
      this.rewardsReady = true;
    },
    async getRating(test) {
      let rang = 0;
      let r;
      let rating = 0;
      let position = null;
      let pointToUp = null;
      const stat = await this.getRewardStat();
      this.stat = JSON.parse(JSON.stringify(stat));
      if (this.auth.id == 36) console.log('get stat', stat);
      if (stat) {
        rating = stat.find((s) => s.name == 'user-rating')?.value || 0;
        pointToUp = stat.find((s) => s.name == 'point-level-up')?.value || 0;
        position = stat.find((s) => s.name == 'user-position')?.value || null;
        r = stat.find((s) => s.name == 'user-rang')?.value || 0;
        rang = r > 1000 ? 5 : r > 500 ? 4 : r > 200 ? 3 : r > 60 ? 2 : r > 20 ? 1 : 0;
      }
      //console.log('res', { rating, position, rang, r });
      this.currentRating = { regdate: new Date().datetime, value: rating, rang, position, test, pointToUp };
    },

    async setRewardStat(stat = []) {
      //console.log('set stat', stat, this.auth.id);
      if (!this.auth.id) return;
      if (!stat.length) return;
      try {
        const cipher = crypto.createCipher('aes-256-cbc', this.$root.secret);
        let encryptedData;
        let dataString = JSON.stringify(stat);
        encryptedData = cipher.update(dataString, 'utf-8', 'hex');
        encryptedData += cipher.final('hex');
        const response = await fetch(this.$root.config.baseUrl + '/rewardStat', {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: encryptedData }),
        });
        if (!response.ok) {
          console.error('Ошибка при отправки статистики');
          return false;
        }
      } catch (error) {
        console.error('Ошибка при отправки статистики', error);
        return false;
      }
      return true;
    },
    async getRewardStat() {
      try {
        const response = await fetch(this.$root.config.baseUrl + '/getUserStat', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        if (!response.ok) {
          console.error('Ошибка при получении данных статистики пользователя');
          return false;
        }

        const data = await response.json();
        return data;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    setRewards(rewards) {
      return;
      if (this.auth.id == 36) {
        console.log('set rewards', rewards);
      }

      for (let i = 0; i < rewards.length; i++) {
        const r = rewards[i];
        r['date_start'] = this.date_start.date;
        r['date_end'] = this.date_end.date;
        try {
          let d = new URLSearchParams({ data: JSON.stringify(r) }).toString();
          fetch('/setReward', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: d,
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Ошибка при выполнении запроса');
              }
              return response;
            })
            .then((data) => {
              // Обработка успешного ответа
            })
            .catch((error) => {
              console.error(error);
            });
        } catch (error) {
          // console.error(error);
        }
      }
    },
    async getRewards(r) {
      try {
        r['start'] = r.start || this.date_start.date;
        r['end'] = r.end || this.date_end.date;
        let d = new URLSearchParams({ data: JSON.stringify(r) }).toString();
        const response = await fetch(this.$root.config.baseUrl + '/getRewards', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: d,
        });
        if (!response.ok) {
          console.error('Ошибка при получении данных рейтинга');
          //throw new Error('Ошибка при выполнении запроса');
          return false;
        }
        const data = await response.json();
        //console.log('reward response json', data, r);
        return data;
      } catch (error) {
        //console.error(error);
        return false;
      }
    },
  },
};
