<template>
  <div>
    <div v-if="isAnalys" style="text-align: center; margin: 20px 0">
      <button class="btn btn-primary" @click="$emit('endAnalis')">Завершить проверку.</button>
    </div>
    <div style="flex-flow: row; display: flex; justify-content: space-between; align-items: center">
      <slot name="title" />
    </div>
    <hr class="mt-1" />
    <div v-if="mainBilet.images && mainBilet.images.length > 0">
      <div style="flex-flow: row; display: flex; justify-content: center; align-items: center">
        <div v-for="(el, key) in mainBilet.images" :key="key">
          <div>
            <img :src="$root.config.baseUrl + '/image/load/' + el.image" style="width: 100%" />
            <div>{{ el.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="flex-flow: row; display: flex; justify-content: center; align-items: center; height: 160px">
      <h2>Вопрос без рисунка</h2>
    </div>
    <div style="margin: 15px 0">
      <span class="badge badge-pill badge-info mr-1" style="background-color: #06163a" @click.stop="getTranslate()">
        {{ lang == "ru" ? "KG" : "RU" }}
      </span>
      <span v-if="canHelp" class="badge badge-pill badge-info" style="background-color: #06163a" @click="showHelp = !showHelp">{{ $t("?") }}</span>
      <b> {{ mainBilet.ques }}</b>
    </div>
    <div v-if="mainBilet.answers2">
      <div v-for="(el, key) in mainBilet.answers2" :key="key">
        <button
          v-if="isAnalys"
          class="btn btn-secondary py-1 px-2"
          style="display: block; width: 100%; margin: 2px 0"
          :style="{
            backgroundColor: mainBilet.answers2[key].istrue == 1 ? 'green' : 'rgb(90, 98, 104)',
            border: el.key == results[qId].num_a ? '3px solid rgb(46, 24, 198)' : '',
          }"
        >
          {{ el.name }}
        </button>
        <button v-else @click="$emit('onAnswer', el.key)" class="btn btn-secondary py-1 px-2" style="display: block; width: 100%; margin: 2px 0">
          {{ el.name }}
          {{ isAdmin && el.istrue == 1 ? "'" : "" }}
        </button>
      </div>
    </div>
    <div>
      <div v-if="showHelp">
        <div class="card">
          <div class="card-body">
            <span v-if="mainBilet.type == 1" class="badge badge-pill badge-success">{{ $t("Экзаменационный вопрос") }}</span>
            <span v-else-if="mainBilet.type == 2" class="badge badge-pill badge-warning">{{ $t("Дополнительный вопрос") }}</span>
            <div class="py-2" v-html="mainBilet.help"></div>
            <div class="alert alert-success" role="alert">
              <b>{{ $t("ОТВЕТ") }}:</b> {{ trueAnswer }}
            </div>
            <p v-if="mainBilet.url">
              <a :href="'https://joldo.kg/' + mainBilet.url + '.html'" target="_blank"><font color="#085294">Смотреть пояснения</font></a>
              |
              <a href="https://forum.joldo.kg" target="_blank"><font color="#085294">Обсудить на форуме</font></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { testing } from "@/components/mixings";
export default {
  mixins: [testing],
  props: {
    canHelp: { type: Boolean, default: true },
    isHard: Boolean,
    isAnalys: { type: Boolean, default: false },
    isHelp: Boolean,
    bilet: { type: Object, default: () => {} },
    results: { type: Object, default: () => {} },
    qId: Number,
  },
  data() {
    return {
      mainLang: null,
      mainBilet: {},
      showHelp: false,
      useHelp: 0,
      lang: this.$parent.lang,
    };
  },
  computed: {
    trueAnswer() {
      let res = this.mainBilet.answers.find(a => {
        return a.istrue == 1;
      });
      return res.name || null;
    },
  },
  created() {
    this.makeBilet();
  },
  watch: {
    isHelp(v) {
      this.showHelp = v;
    },
    qId(v) {
      this.makeBilet();
    },
    showHelp(v) {
      this.$emit("onUseHelp", this.qId);
    },
  },
  methods: {
    makeBilet() {
      this.useHelp = 0;
      this.mainLang = null;
      this.showHelp = false;
      this.mainBilet = Object.assign({}, this.bilet);
      this.mainBilet["answers2"] = this.shuffleArray(this.bilet.answers);
    },
    async getTranslate() {
      let l = Object.assign({}, this.mainBilet.lang_main);
      if (this.lang == "ru") l = Object.assign({}, this.mainBilet.lang_data);
      this.lang = this.lang == "ru" ? "kg" : "ru";
      let d = Object.assign({}, this.mainBilet);
      if (l?.ques) d.ques = l?.ques;
      if (l?.help) d.help = l?.help;
      if (l?.answers) d.answers = [...l.answers];
      try {
        let answers2 = JSON.parse(JSON.stringify(d.answers2));
        answers2.forEach(a => (a.name = d.answers[a.key]?.name));
        d["answers2"] = answers2;
      } catch (error) {}
      this.mainBilet = Object.assign({}, d);
    },
    async getTranslate_() {
      if (!this.mainLang) {
        const data = await this.getQuestions([this.bilet.lang_id]);
        const q = data[0];
        let lang = {};
        for (let i in q) {
          try {
            lang[i] = JSON.parse(q[i]);
          } catch (err) {
            lang[i] = q[i];
          }
        }
        this.mainLang = Object.assign({}, this.mainBilet);
        try {
          let answers2 = JSON.parse(JSON.stringify(this.mainBilet.answers2));
          answers2.forEach(a => (a.name = lang.answers[a.key]?.name));
          lang["answers2"] = answers2;
        } catch (error) {}
        this.mainBilet = Object.assign({}, lang);
      } else {
        this.mainBilet = Object.assign({}, this.mainLang);
        this.mainLang = null;
      }
    },
    shuffleArray(arr) {
      const shuffledArray = arr.slice();
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      shuffledArray.forEach((element, index) => {
        element.key = arr.indexOf(element);
      });
      return shuffledArray;
    },
  },
};
</script>