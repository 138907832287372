/** @format */
const secret = 'DSFw#RSDGh54#$RDF';
const crypto = require('crypto');

export default {
  data() {
    return {
      groupBilet: null,
    };
  },
  created() {},
  destroyed() {},
  computed: {
    isAdmin() {
      return this.$root.authData && [36, 40].includes(this.$root.authData.id);
    },
  },
  methods: {
    async onStart(b = 0) {
      console.log(this.type);
      if (!this.loadedList) return;
      if (this.type == 'marathon') {
        this.onStartFavorites();
        return;
      }
      if (this.type == 'topic') {
        this.onStartGroup(this.groupBilet);
        return;
      }
      this.selected = b;
      if (this.prepare) return;
      this.prepare = this.type;
      this.start = false;
      try {
        let hard = this.isHard;
        let bilet = [];
        this.maxBQ = 20;
        this.onStop();
        this.isHard = hard;
        // this.maxBQ = this.maxQ,
        // let app = this;
        this.qId = 1;
        this.biletId = b;
        if (b > 0) {
          this.bilets[0] = {};
          let i = 0;
          let q_list = this.q_list.filter((q) => {
            return q.num_bilet == b && this.typeP.includes(q.type);
          });
          for (let index = 0; index < q_list.length; index++) {
            const q = q_list[index];
            i++;
            bilet.push(q);
          }
          if (!i) return;
          await this.beginTest(bilet);
          this.isHard = hard;
          this.setTimerTest(this.maxtime);
          this.prepare = false;
          return;
        } else {
          console.log('shuffle');
          this.biletId = 0;
          bilet = await this.genShuffleBilet();
          await this.beginTest(bilet);
          this.isHard = hard;
          this.setTimerTest(this.maxtime);
          this.prepare = false;
          return;
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    setTimerTest(t, type = 1) {
      if (type == 1) {
        let timer = 60 * parseInt(t);
        this.timer = setInterval(() => {
          if (this.results[this.qId].result == 0) this.results[this.qId].time++;
          this.timeUse++;
          this.time = this.getTimeStr(timer);

          if (--timer < 0) {
            clearInterval(this.timer);
            this.onEnd(true);
          }
        }, 1000);
      } else {
        let timer = 0;
        this.timer = setInterval(() => {
          this.time = this.getTimeStr(timer);
          this.timeUse++;
          timer++;
        }, 1000);
      }
    },
    async onStartGroup({ id, items, total }) {
      //console.log('oooooooooooo', { id, items, total });
      this.prepare = true;
      this.loadProgress = 0;
      this.start = false;
      this.isHard = false;
      this.bilets[0] = {};
      let bilet = [];
      if (items) {
        this.q_list.forEach((q) => {
          if ((items[0] == -1 || items.includes(q.id)) && q.gr == id) {
            bilet.push(q);
          }
        });

        this.prepare = false;
        //this.groupBilet = { id, group };
        this.groupBilet = { id, items, total };
        this.beginTest(bilet);
      }
    },

    async beginTest(bilet = []) {
      this.$emit('changeAD');
      let count = bilet.length;
      if (!count) return;

      this.qId = 1;
      this.maxBQ = count;
      let need_load = [];
      bilet.forEach((b) => {
        if (!this.q_full[b.id]) need_load.push(b.id);
      });
      if (need_load.length) {
        let ok;
        if (this.auth.id == 36) ok = await this.loadQuestion1(need_load);
        else ok = await this.loadQuestion1(need_load);
        if (!ok) return;
      }
      let res = {};
      let i = 0;
      bilet.forEach((b) => {
        i++;
        res[i] = this.q_full[b.id];
      });
      this.bilets[0] = res;
      this.qId = 0;
      this.qId = 1;
      this.onStop();

      this.prepare = false;
      this.start = true;
    },
    async getQuestions(list) {
      let data;
      try {
        let d = new URLSearchParams({ data: JSON.stringify(list), lang: this.lang }).toString();
        const response = await fetch(this.$root.config.baseUrl + '/getQuestions2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: d,
        });
        if (!response.ok) {
          console.error('Ошибка при получении вопросов');
          return false;
        }
        const res = await response.text();
        const decipher = crypto.createDecipher('aes-256-cbc', secret);
        data = decipher.update(res, 'hex', 'utf-8');
        data += decipher.final('utf-8');

        data = JSON.parse(data);
        for (let i = 0; i < data.length; i++) {
          let d = data[i];
          data[i].lang_main = { ques: d.ques, answers: [...d.answers], help: d.help, group_name: d.group_name };
          if (this.lang != 'ru') {
            console.log('not ru');
            if (d.lang_data?.ques) data[i].ques = d.lang_data?.ques;
            if (d.lang_data?.help) data[i].help = d.lang_data?.help;
            if (d.lang_data?.group_name) data[i].group_name = d.lang_data?.group_name;
            if (d.lang_data?.answers) data[i].answers = [...d.lang_data.answers];
          }
        }
      } catch (error) {
        console.log('error in get question', error);
      }
      return data;
    },
    async loadQuestion1(list = []) {
      this.loadData = 1;

      let data = [];
      try {
        for (let i = 0; i < list.length; i += 20) {
          const l = list.slice(i, i + 20);

          try {
            let q = await this.getQuestions(l);
            //console.log(q.length, data.length);
            data = [...data, ...q];
          } catch (error) {
            console.log('partial load error', error);
            return false;
          }
          this.loadProgress = ((data.length * 100) / list.length).toFixed(1);
        }
        // console.log(data);
        data.forEach((q) => {
          let res = {};
          for (let i in q) {
            try {
              res[i] = JSON.parse(q[i]);
            } catch (err) {
              res[i] = q[i];
            }
          }

          this.q_full[res.id] = res;
        });
        this.loadData = 0;
        return true;
      } catch (error) {
        console.error(error);
        this.loadData = 0;
        return false;
      }
    },
    async onStartFavorites() {
      if (this.prepare) return;
      this.prepare = true;
      this.start = false;
      this.isHard = false;
      this.qId = 1;
      this.bilets[0] = {};
      let bilet = [];
      let i = 0;
      let list = [];
      if (this.type == 'myErrors') list = this.errors;
      else if (this.type == 'marathon') {
        list = this.q_list
          .filter((q) => {
            return this.typeP.includes(q.type);
          })
          .map((q) => {
            return q.id;
          });
      } else list = this.favorites;
      //console.log('fav', list);
      let q_list = this.q_list.filter((q) => {
        return list.includes(q.id);
      });

      for (let index = 0; index < q_list.length; index++) {
        const q = q_list[index];
        i++;
        bilet.push(q);
      }
      if (!i) return;
      if (this.type == 'marathon') {
        let l = bilet.slice();
        function getRandomInt(max) {
          return Math.floor(Math.random() * (max + 1));
        }
        l.sort(() => getRandomInt(1) - 0.5);
        bilet = [...l];
      }
      await this.beginTest(bilet);
      if (this.type == 'marathon') this.setTimerTest(0, 2);
      this.prepare = false;
    },
    async genShuffleBilet() {
      this.bilets[0] = {};
      let q_list = this.q_list.filter((q) => {
        return this.typeP.includes(q.type);
      });

      let used = [];
      let maxQ = q_list.length;
      let bilet = [];

      //console.log('количество вопросов', maxQ, this.maxQ);
      for (let i = 1; i <= this.maxQ; i++) {
        let b, q, qq, tt, id;
        tt = 1;
        do {
          tt++;
          qq = Math.floor(Math.random() * maxQ);
          id = q_list[qq].id;
          b = q_list[qq]?.num_bilet;
          q = q_list[qq].num_q;
        } while ((used.includes(id) || !(q && b)) && tt < 25);
        used.push(id);
        bilet.push(q_list[qq]);
      }
      let uu = q_list
        .filter((q) => used.includes(q.id))
        .map((q) => {
          return { тип: q.type, Билет: q.num_bilet, Вопрос: q.num_q };
        });
      this.uu = uu;
      //console.log(used);
      return bilet;
    },
  },
};
