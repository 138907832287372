/** @format */
const secret = 'DSFw#RSDGh54#$RDF';
const crypto = require('crypto');

export default {
  data() {
    return {
      infoResult: null,
      infoPrize: null,
      infoPrize2: [],
      n: {
        start: '"Начало положено"',
        progress25: '"Прогресс 25%"',
        progress50: '"Прогресс 50%"',
        progress75: '"Прогресс 75%"',
        progress100: '"Прогресс 100%"',
        top10: '"ТОП 10"',
        top30: '"ТОП 30"',
        top100: '"ТОП 100"',
        completeTopic: '"Прохождение темы"',
        completeBilet: '"Прохождение билета"',
        noError5: '"Без ошибок 5 раз подряд"',
        fastResult: '"Быстрый результат"',
        top1: '"Покоритель рейтинга"',
        maraphon: '"Марафонец"',
        expertJoldo: '"Знаток JOLDO"',
        topic26: '"Знаток разметки"',
        topic6: '"Повелитель светофоров"',
        topic8: '"Король маневров"',
        topic13: '"Покоритель перекрестков"',
      },
    };
  },
  created() {},
  destroyed() {},
  computed: {},
  methods: {
    async makeUserInfo() {
      if (!this.auth?.id) return;
      this.infoResult = null;
      let n = {};
      let info;
      let resp = await fetch(this.$root.config.baseUrl + '/getUserInfo', {
        method: 'GET',
        credentials: 'include',
      });
      if (resp.ok) info = await resp.json();
      else {
        this.infoResult = 'Ошибка получения данных пользователя';
      }
      //console.log('makeUserInfo', { info, type: this.type, ishard: this.isHard, results: this.results, stat: this.stat });
      if (this.type == 'topic') {
        const groupId = this.groupBilet?.id;
        const group = this.groups.find((g) => g.id == groupId);
        let isPost = 0;
        console.log('this.groupBilet', this.groupBilet);
        let topics = JSON.parse((info.find((i) => i.name == 'topic') || {})?.data || '{}');
        let ttt = {};
        for (const i in topics) {
          const r = topics[i];
          if (r?.sort) {
            ttt[i] = Object.assign({}, r);
          } else {
            isPost = 1;
            let g = this.groups.find((g) => g.id == i);
            if (g) {
              r['sort'] = g.sort;
              ttt[g.sort] = Object.assign({}, r);
            }
          }
        }
        topics = Object.assign({}, ttt);

        const topic = topics?.[group.sort] || {};
        //console.log({ topic }, this.groupBilet);
        let done = 0,
          total = this.groupBilet.total;
        for (const i in this.results) {
          const r = this.results[i];
          if (r.type !== 2) {
            // total++;
            if (r.result == 1 && !r.useHelp) done++;
          }
        }
        let t = { done, total, group_id: groupId, name: group?.name || '???', sort: group.sort };
        if ((topic?.['done'] || -1) < done || topic.total !== total) {
          isPost = 1;
          this.infoResult = `Тема: "${t.name}". ${t.done} из ${t.total}`;
          topics[group.sort] = t;
        }
        if (done == total) n[group.sort] = t;

        //console.log({ topics, topic, isPost });
        if (isPost) this.setUserInfo('topic', topics);
      }

      if (this.type == 'bilet') {
        //console.log({ biletId: this.biletId, typeP: this.typeP }, this.typeP.join());
        let isPost = 0;
        let done = 0,
          total = 0;
        for (const i in this.results) {
          const r = this.results[i];
          if (r.type == this.typeP.join()) {
            total++;
            if (r.result == 1 && !r.useHelp) done++;
          }
        }
        if (this.biletId > 0 && this.typeP.join() == '1') {
          let topics = JSON.parse((info.find((i) => i.name == 'bilet') || {})?.data || '{}');
          const topic = topics?.[this.biletId] || {};
          let t = { done, total, name: 'Билет ' + this.biletId || '???' };
          if ((topic?.['done'] || -1) < done) {
            isPost = 1;
            this.infoResult = `${t.name}. ${t.done} из ${t.total}`;
            topics[this.biletId] = t;
          }
          if (done == total) n[this.biletId] = t;

          //console.log({ topics, topic, isPost });
          if (isPost) this.setUserInfo('bilet', topics);
        } else if (this.biletId > 0 && this.typeP.join() == '2') {
          let topics = JSON.parse((info.find((i) => i.name == 'biletJoldo') || {})?.data || '{}');
          console.log('biletJoldo', done, total, this.results);
          if (done == total) {
            let t = { done, total, name: 'Билет ' + this.biletId || '???' };
            if (!topics?.[this.biletId]) isPost = 1;
            topics[this.biletId] = t;
            n = { biletJoldo: topics };
            if (isPost) this.setUserInfo('biletJoldo', topics);
          }
        } else return;
      }
      this.makePrize(info, n);
    },
    makePrize(info, n = {}) {
      if (!this.auth.id) return;
      this.infoPrize = null;
      let infoPrize = [];
      let infoPrize2 = [];
      let prize = JSON.parse((info.find((i) => i.name == 'prize') || {})?.data || '{}');
      let list = Object.keys(prize);
      let newPrize = {};
      let userRating = (this.stat.find((s) => s.name == 'user-rating') || {})?.value || 0;
      let position = (this.stat.find((s) => s.name == 'user-position') || {})?.value || 0;
      let error0 = (this.stat.find((s) => s.name == 'error-0-row') || {})?.value || 0;
      //marathon;
      if (this.type == 'marathon') {
        const results = this.results;
        let res = { time: 0, correct: 0, skip: 0, error: 0 };
        for (let i = 1; i <= this.maxBQ; i++) {
          res.time += results[i].time;
          res.correct += results[i].result === 1 ? 1 : 0;
          res.error += results[i].result === 2 ? 1 : 0;
          res.skip += results[i].result === 0 ? 1 : 0;
        }
        console.log('maraphone', res);
        if (res.skip == 0 && res.error <= 200) {
          newPrize['maraphon'] = prize?.maraphon || [];
          newPrize.maraphon.push({ regdate: new Date().datetime, result: res });
        }
      }
      if (this.type == 'exam') {
        let err = 0;
        let time = 0;
        for (const i in this.results) {
          const r = this.results[i];
          if (r.result !== 1) err++;
          time += r.time;
        }
        if (time <= 30 && err == 0) {
          newPrize['fastResult'] = prize?.fastResult || [];
          newPrize.fastResult.push({ regdate: new Date().datetime, time });
          infoPrize.push(this.n['fastResult']);
          infoPrize2.push({ name: 'fastResult', text: this.n['fastResult'] });
        }
        if (!list.includes('start') && err == 0) {
          newPrize['start'] = [{ regdate: new Date().datetime }];
          infoPrize.push(this.n['start']);
          infoPrize2.push({ name: 'start', text: this.n['start'] });
        }
        if (error0 > 0 && err == 0) {
          if (error0 % 5 === 0) {
            newPrize['noError5'] = prize?.noError5 || [];
            newPrize.noError5.push({ regdate: new Date().datetime });
            infoPrize.push(this.n['noError5']);
            infoPrize2.push({ name: 'noError5', text: this.n['noError5'] });
          }
        }
        if (position && position > 0 && userRating > 100) {
          [100, 30, 10, 1].forEach((p) => {
            if (!list.includes(`top${p}`)) {
              if (position <= p) {
                newPrize[`top${p}`] = [{ regdate: new Date().datetime, position, userRating }];
                infoPrize.push(this.n[`top${p}`]);
                infoPrize2.push({ name: `top${p}`, text: this.n[`top${p}`] });
              }
            }
          });
        }
      }

      if (!list.includes('progress100')) {
        let p = this.q_list.filter((q) => q.type == 1).length * 2;
        let t = JSON.parse((info.find((i) => i.name == 'topic') || {})?.data || '{}');
        let b = JSON.parse((info.find((i) => i.name == 'bilet') || {})?.data || '{}');
        let d = 0;

        for (const key in t) {
          const el = t[key];
          d += el.done || 0;
        }
        for (const key in b) {
          const el = b[key];
          d += el.done || 0;
        }
        let pr = (100 * d) / p;
        if (!list.includes('progress25') && pr >= 25) {
          newPrize['progress25'] = [{ regdate: new Date().datetime, total: p, done: d }];
          infoPrize.push(this.n['progress25']);
          infoPrize2.push({ name: 'progress25', text: this.n['progress25'] });
        }
        if (!list.includes('progress50') && pr >= 50) {
          newPrize['progress50'] = [{ regdate: new Date().datetime, total: p, done: d }];
          infoPrize.push(this.n['progress50']);
          infoPrize2.push({ name: 'progress50', text: this.n['progress50'] });
        }
        if (!list.includes('progress75') && pr >= 75) {
          newPrize['progress75'] = [{ regdate: new Date().datetime, total: p, done: d }];
          infoPrize.push(this.n['progress75']);
          infoPrize2.push({ name: 'progress75', text: this.n['progress75'] });
        }
        if (!list.includes('progress100') && pr >= 100) {
          newPrize['progress100'] = [{ regdate: new Date().datetime, total: p, done: d }];
          infoPrize.push(this.n['progress100']);
          infoPrize2.push({ name: 'progress100', text: this.n['progress100'] });
        }
      }
      for (const key in n) {
        if (this.type == 'topic') {
          const group = this.groups.find((g) => g.sort == key);
          console.log('topic group ', group.sort, list);
          if ([6, 8, 13, 26].includes(group.sort) && !list.includes(`topic${group.sort}`)) {
            newPrize[`topic${group.sort}`] = [{ regdate: new Date().datetime }];
            infoPrize.push(this.n[`topic${group.sort}`]);
            infoPrize2.push({ name: `topic${group.sort}`, text: this.n[`topic${group.sort}`] });
          }
          newPrize['completeTopic'] = prize?.completeTopic || [];
          if (!newPrize.completeTopic.find((p) => p.sort == key)) {
            newPrize.completeTopic.push({ id: group.id, regdate: new Date().date, name: group?.name || '???', sort: group.sort });
            infoPrize.push(this.n['completeTopic']);
            infoPrize2.push({ name: 'completeTopic', text: this.n['completeTopic'] });
          }
        } else if (this.type == 'bilet') {
          if (this.typeP.join() == '1') {
            newPrize['completeBilet'] = prize?.completeBilet || [];
            if (!newPrize.completeBilet.find((p) => p.id == key)) {
              newPrize.completeBilet.push({ id: key, regdate: new Date().date, name: 'Билет ' + this.biletId || '???' });
              infoPrize.push(this.n['completeBilet']);
              infoPrize2.push({ name: 'completeBilet', text: this.n['completeBilet'] });
            }
          } else if (this.typeP.join() == '2') {
            if (!list.includes('expertJoldo')) {
              const cc = this.listBiletExt.length;
              if (cc && cc == Object.keys(n[key]).length) {
                newPrize['expertJoldo'] = [{ regdate: new Date().datetime }];
                infoPrize.push(this.n['expertJoldo']);
                infoPrize2.push({ name: 'expertJoldo', text: this.n['expertJoldo'] });
              }
            }
          }
        }
      }

      if (this.auth.id == 36) console.log('prize result', { prize, newPrize });
      if ([36, 40].includes(this.auth.id) || true)
        if (Object.keys(newPrize).length && infoPrize.length) {
          this.infoPrize = infoPrize2;
          this.setUserInfo('prize', Object.assign({}, prize, newPrize));
        }
    },
    async setUserInfo(n, d) {
      if (!n || !d) return;
      try {
        const cipher = crypto.createCipher('aes-256-cbc', this.$root.secret);
        let encryptedData;
        let dataString = JSON.stringify({ name: n, data: d });
        encryptedData = cipher.update(dataString, 'utf-8', 'hex');
        encryptedData += cipher.final('hex');
        const response = await fetch(this.$root.config.baseUrl + '/setUserInfo', {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ data: encryptedData }),
        });
        if (!response.ok) {
          console.error('Ошибка при отправки статистики');
          return false;
        }
      } catch (error) {
        console.error('Ошибка при отправки статистики', error);
        return false;
      }
      return true;
    },
  },
};
