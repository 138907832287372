<template>
  <div>
    <div style="text-align: center; margin: 30px 0 0 0">
      <img :src="rangImage" class="img-fluid" alt="Ваш ранг" style="height: 150px" />
    </div>
    <div v-if="!auth.id">
      <p>К сожалению ваш результат не будет сохранен.</p>
      <p>Зарегистрируйтесь на сайте.</p>
    </div>
    <div v-else>
      <p class="mt-4">Позиция в рейтинге</p>
      <h2 style="color: #ce0000; margin: 0 0 20px">
        <b>{{ rating && rating.position }}</b>
      </h2>
    </div>
    <div v-if="nowList.find(f => ['rewards'].includes(f.type))" class="my-1">Награды за тест:</div>
    <div class="d-flex" style="justify-content: center">
      <img v-for="(el, i) in nowList.filter(f => ['rewards'].includes(f.type))" :key="i" :src="getImage(el)" class="img-fluid" :alt="el.title" style="height: 100px" />
    </div>
  </div>
</template>
<script>
import { reward } from "@/components/mixings";
export default {
  mixins: [reward],
  props: {
    data: { type: Array, default: () => [] },
    rating: { type: Object, default: () => {} },
  },
  data() {
    return {
      rewardsList: [],
      nowList: [],
      ratingPosition: null,
    };
  },
  created() {
    this.calcRewards("now");
    this.currentRating = this.rating;
    //this.getRatingPosition();
  },
  destroyed() {},
  watch: {
    rating() {},
  },
  computed: {
    auth() {
      const res = this.$root.authData || {};
      return res;
    },
  },
  methods: {
    getImage(el) {
      let res = "https://joldo.kg/uploads/rewards/";
      return el.image ? res + el.image : null;
    },
    async calcRewards(type = "total") {
      let list = [];
      if (type == "total") {
        let rewards = await this.getRewards({ status: 2 });
        rewards.forEach(r => {
          let rew = this.readReward(r.name);
          list.push({ name: r.name, title: rew.title, count: r.count, points: r.points, new: false });
        });
      }
      if (type == "now1") {
        this.data.forEach(r => {
          let rew = this.readReward(r.name);
          list.push({ name: r.name, title: rew.title, count: r.count, points: r.points, new: false });
        });
      }
      if (type == "now") {
        let l = [];
        //console.log("current rewards", [...this.data]);
        this.data.forEach(r => {
          if (r.status == 2) {
            let rew = this.readReward(r.name);
            l.push({ name: r.name, title: rew.title, count: r.count, points: r.points, new: true, type: rew.type, image: rew.image });
          }
        });
        this.nowList = l;
      }
      this.rewardsList = list;
    },
  },
};
</script>