<template>
  <div style="max-width: 520px; margin: auto">
    <div v-if="$parent.type == 'myErrors'" style="text-align: center; margin: 100px 0 0 0"></div>
    <div v-else style="text-align: center; margin: 100px 0 0 0">
      <div v-if="$parent.end.timer">
        <p>К сожаленю ваше время истекло...</p>
      </div>
      <div v-if="$parent.type !== 'marathon' && $parent.isHard">
        <div v-if="$parent.countResults(2) + $parent.countResults(0) > 2">
          <h3 v-if="!$parent.isHard" style="color: red; margin-bottom: 10px">Вы не прошли экзамен</h3>
        </div>
        <h3 v-else style="color: #397b21; margin-bottom: 10px"><b>ЭКЗАМЕН СДАН</b></h3>
      </div>
      <div v-else>
        <h3 style="color: #397b21; margin-bottom: 10px"><b>ТЕСТ ЗАВЕРШЕН</b></h3>
      </div>

      <div v-if="!$parent.rewardsReady && $parent.isHard">
        <div v-if="!$parent.auth.id">
          <p>К сожалению ваш результат не будет сохранен.</p>
          <p>Зарегистрируйтесь на сайте.</p>
        </div>
        <p v-else>Идет подсчет результатов....</p>
      </div>

      <Rewards2 v-else-if="$parent.showRewards && $parent.isHard" :data="$parent.currentRewards" :rating="$parent.currentRating" />
      <div v-if="$parent.rewardsReady || true" style="text-align: center">
        <table style="width: 100%; margin: 20px 0; font-size: 14px; line-height: 1.6; border-spacing: 0; border-collapse: collapse">
          <tbody>
            <tr style="background-color: #f1f1f1">
              <td style="text-align: left; padding: 8px">✅ Правильно:</td>
              <td style="text-align: right; padding: 8px">
                <b>{{ $parent.countResults(1) }}</b>
              </td>
            </tr>
            <tr style="background-color: #ffffff">
              <td style="text-align: left; padding: 8px">❌ Не правильно:</td>
              <td style="text-align: right; padding: 8px">
                <b>{{ $parent.countResults(2) }}</b>
              </td>
            </tr>
            <tr style="background-color: #f1f1f1">
              <td style="text-align: left; padding: 8px">⚡ Не выполнено:</td>
              <td style="text-align: right; padding: 8px">
                <b>{{ $parent.countResults(0) }}</b>
              </td>
            </tr>
            <tr v-if="$parent.timeUse" style="background-color: #ffffff">
              <td style="text-align: left; padding: 8px">⌛ Потрачено времени:</td>
              <td style="text-align: right; padding: 8px">
                <b>{{ $parent.getTimeStr($parent.timeUse) }}</b>
              </td>
            </tr>
            <tr style="background-color: #f1f1f1">
              <td style="text-align: left; padding: 8px; font-weight: bold">Получено баллов:</td>
              <td style="text-align: right; padding: 8px; font-weight: bold">
                <b>{{ getPoints() }}</b>
              </td>
            </tr>
            <tr style="background-color: #ffffff">
              <td style="text-align: left; padding: 8px; font-weight: bold">Всего баллов:</td>
              <td style="text-align: right; padding: 8px; font-weight: bold">
                <b>{{ $parent.currentRating && $parent.currentRating.value }}</b>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="$parent.infoResult" class="mt-2">
          <h4>Обновлен прогресс</h4>
          <p>{{ $parent.infoResult }}</p>
        </div>
        <div v-if="$parent.infoPrize" class="mt-2">
          <p>Получены награды:</p>
          <img v-for="(line, i) in $parent.infoPrize" :key="`img-${i}`" :src="`https://joldo.kg/uploads/images/prize/${line.name}.png`" style="max-height: 120px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getPoints() {
      return this.$parent.currentRewards?.find(el => el.status == 2)?.points || 0;
    },
  },
};
</script>

<style>
</style>
